import React, { useEffect, useState } from 'react';
import * as Styled from './StyledComponents';
import {doc, setDoc} from 'firebase/firestore';
import {db, auth} from './firebase';

import { useNavigate, Link} from 'react-router-dom';

function Wishlist() {
  const navigate = useNavigate();
  const [wishlist, setWishlist] = useState(()=> {
    return (localStorage.getItem('wishlist') ? JSON.parse(localStorage.getItem('wishlist')) : []);
  }); //from Dashboard
  const [newItem, setNewItem] = useState('');
  const [newItemPrice, setNewItemPrice] = useState('');
  const [doUpdate, setDoUpdate] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [recolist, setrecoList] = useState(() => {
    return localStorage.getItem('recolist') ? JSON.parse(localStorage.getItem('recolist')):[];
  }); //from Dashboard
  const user = auth.currentUser; 
  console.log("Wishlist set to :", wishlist);
  //  { id: 1, title: "Card 1", content: "This is the content of card 1" },
  //  { id: 2, title: "Card 2", content: "This is the content of card 2" },
  //  { id: 3, title: "Card 3", content: "This is the content of card 3" },
  //  { id: 4, title: "Card 4", content: "This is the content of card 4" },
 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalLink, setModalLink] = useState(''); 
  const [modalName, setModalName] = useState('');
  const [modalPrice, setModalPrice] = useState('');

  const openModal = (link, name, price) => {
    if(typeof name !== 'string' ) {
      console.error("Invalid name type:", typeof name);
      return;
    }
    const parsedPrice = parseFloat(price);
    if (isNaN(parsedPrice)) {
      console.error("Invalid price type:", parsedPrice);
      return;
    }
    setModalLink(link);
    setModalName(name);
    setModalPrice(price); 
    setIsModalOpen(true);
  };
  
  const closeModal = () => {
    setIsModalOpen(false);
    setModalLink('');
  };

  const nextCard = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % recolist.length);
  };

  const prevCard = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + recolist.length) % recolist.length);
  };

  const fillInputs = () => {
    setNewItem(modalName);
    setNewItemPrice(modalPrice);
    closeModal();
  };

  const Modal = ({ isOpen, onClose, link , onFillInputs}) => {
    if (!isOpen) return null;
  
    return (
      <Styled.Modal>
        <Styled.ModalContent>
          <Styled.ModalAddButton onClick={onFillInputs}>
            Add to your List
          </Styled.ModalAddButton>
          <Styled.ModalCloseButton onClick={onClose}>
            Close Window
          </Styled.ModalCloseButton>
          <Styled.ModalIframe src={link} />
        </Styled.ModalContent>
      </Styled.Modal>
    );
  };

  useEffect(() => {
    const updateWishList = async() => {
      try{
        console.log("Update database for wishlist from user:", user.uid);
        setWishlist(JSON.parse(localStorage.getItem('wishlist'))|| []);
        const wishDocRef =  doc(db,'wishlist', user.uid);
        await setDoc(wishDocRef, {userlist: wishlist}, {merge:true});

        console.log('Updated wishlist for user:', user.uid);
        setTimeout(() => {navigate('/dashboard')}, 1000);
      } catch (error) {
        console.error('Error updating wishlist data',error);
      }
    };
    if(doUpdate) {
      localStorage.setItem('wishlist', JSON.stringify(wishlist));
      updateWishList(); //write to firestore for this user
    } else {
      console.log("No do update");
    }
  }, [doUpdate, user]);

  const delItem = (index) => {
    const updatedList = wishlist.filter((_, i) => i!==index);
    setWishlist(updatedList);
    setDoUpdate(true);
  };

  if (user) {
    const addItem = () => {
      if (newItem.trim()) {
        setWishlist([...wishlist, {name: newItem.trim(), price: newItemPrice}]);
        setNewItem('');
        setNewItemPrice('');
        setDoUpdate(true); 
        //console.log("Setting do update to true");
      }
    };
    return (
        <Styled.WishlistContainer>
          <Styled.Title>Wishlist</Styled.Title>
          <Styled.List>
          {wishlist.map((item, index) => (
                <Styled.ListItem key={index}>
                  <span>{item.name} </span>
                  <span> ${item.price}</span>
                  <Styled.CrossButton onClick={()=>delItem(index)}>X</Styled.CrossButton>
                </Styled.ListItem>
              ))}
          </Styled.List>
          <Styled.Input
            type="text"
            value={newItem}
            onChange={(e) => setNewItem(e.target.value)}
            placeholder="Type item here..."
          />
          <Styled.Input
            type="number"
            value={newItemPrice}
            onChange={(e) => setNewItemPrice(e.target.value)}
            placeholder="Type item price here..."
          />
          <Styled.AddButton onClick={addItem}>+ Add to wishlist</Styled.AddButton>
       
          <Styled.Carousel>
          <Styled.CarouselContainer>
            {recolist.map((card, index) => (
              <Styled.CarouselItem key={index} onClick={() => openModal(card.link, card.Name, card.Price)} className={index === currentIndex ? 'active' : ''}>
                <Styled.CardContent>
                  <h4 className="text-xl font-bold mb-4">{card.Name}</h4>
                  <img src={card.Picture} alt="Item" />
                  <p>${card.Price}</p>
                </Styled.CardContent>
              </Styled.CarouselItem>
            ))}
          </Styled.CarouselContainer>
          <Styled.CarouselControls>
            <button
              onClick={prevCard}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            >
              <Styled.ChevronLeft />
              </button>
              <button
                onClick={nextCard}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
              >
                <Styled.ChevronRight />
              </button>
            </Styled.CarouselControls>
          </Styled.Carousel>
          <Modal isOpen={isModalOpen} onClose={closeModal} link={modalLink} onFillInputs={fillInputs} />
          <Link to="/dashboard">Return to Dashboard</Link>
        </Styled.WishlistContainer>

      );
  } else {
    localStorage.clear();
    return (
      navigate('/')
    );
  }
      
}

export default Wishlist;
