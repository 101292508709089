import React from 'react';
import './Footer.css';

const version = 1.82;
function Footer() {
    return(
      <footer className="bg-gray-800 text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between">
          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <h3 className="text-lg font-bold mb-2">Centsible</h3>
            <p>© 2025 Centsible {version} All rights reserved.</p>
            <div className="flex space-x-6">
              <a href="#" className="hover:text-gray-300">Facebook</a>
              <a href="#" className="hover:text-gray-300">Twitter</a>
              <a href="#" className="hover:text-gray-300">Instagram</a>
            </div>
          </div>
        </div>
      </div>
      </footer>
    );
}

export default Footer;