import React, { useState, useEffect } from 'react';
import * as Styled from './StyledComponents';
import { useNavigate , Link} from 'react-router-dom';
import {doc, setDoc} from 'firebase/firestore';
import {auth} from './firebase';
import {db} from './firebase';

function LogSavings() {
  const navigate = useNavigate();
  const [wishlist,setwishList] = useState(JSON.parse(localStorage.getItem('wishlist') || []));
  const [savings, setSavings] = useState(JSON.parse(localStorage.getItem('savings') || 0));
  const [doUpdate, setDoUpdate]= useState(false);
  const user = auth.currentUser; 

  useEffect(() => {
    const updateSavings = async() => {
      try{
        console.log("Update database for savings from user:", user.uid);
        const DocRef =  doc(db,'wishlist', user.uid);
        if(savings!==undefined){
          await setDoc(DocRef, {savings: savings}, {merge:true});
          localStorage.setItem('savings', JSON.stringify(savings));
          //setTimeout(() => navigate('/dashboard'), 3000);
        }
      } catch (error) {
        console.error('Error updating savings data',error);
      }
    };
    updateSavings(); //write to firestore for this user
  }, [doUpdate,user]);


  const handleSavingUpdate = ()=> {
    const newSavings = parseFloat(savings);
    setSavings(newSavings);
    //Update the localStorage
    localStorage.setItem('savings', JSON.stringify(newSavings));
    setDoUpdate(true); 
  };

  return (
    <Styled.SavingsCalculatorContainer>
      <Styled.Card>
      <Styled.Title>Manually Add total money Saved so far</Styled.Title>
      <Styled.Input
            type="number"
            value={savings}
            onChange={(e) => setSavings(e.target.value)}
            placeholder="Type saving amount here..."
      />
      <Styled.CalculateButton onClick={handleSavingUpdate}>Update your Total savings</Styled.CalculateButton>
     
 
      </Styled.Card>
      <Link to="/dashboard">Return to Dashboard</Link>
    </Styled.SavingsCalculatorContainer>
  );
}

export default LogSavings;
