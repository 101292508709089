import React, { useState, useEffect } from 'react';
import * as Styled from './StyledComponents';
import { useNavigate , Link} from 'react-router-dom';
import {doc, setDoc} from 'firebase/firestore';
import {auth} from './firebase';
import {db} from './firebase';

function SavingsCalculator() {
  const navigate = useNavigate();
  const [wishlist,setwishList] = useState(JSON.parse(localStorage.getItem('wishlist') || []));
  const [savingCalc, setSavingCalc]=useState([]);
  const [selectedItem, setSelectedItem] = useState('');
  const [error, setError] = useState('');
  const [goal, setGoal] = useState(() => {
    const storedGoal = JSON.parse(localStorage.getItem('goal') || '[]');
    return Array.isArray(storedGoal) ? storedGoal.map(item => ({...item, needby: new Date(item.needby)})) : [];
  });
  const [date, setDate] = useState(goal.needby || '');
  const income = JSON.parse(localStorage.getItem('income') || 0);
  const target = JSON.parse(localStorage.getItem('target') || 20);
  const [doGoalUpdate, setDoGoalUpdate] = useState(false);
  const user = auth.currentUser; 
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
      const updateGoal = async() => {
        try{
          console.log("Update database for goal from user:", user.uid);
          const DocRef =  doc(db,'wishlist', user.uid);
          await setDoc(DocRef, {saving_goals: goal}, {merge:true});
          closeModal();
        } catch (error) {
          console.error('Error updating goals data',error);
        }
      };
      updateGoal(); //write to firestore for this user
  }, [doGoalUpdate]);
    
  useEffect(() => {
    setwishList(JSON.parse(localStorage.getItem('wishlist') || []));
    if (wishlist.length > 0) {
      setSelectedItem(wishlist[0].name);
    }
  }, []);

  // Get the current date in the format yyyy-mm-dd
  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months start at 0!
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };

  const handleCalculate = () => {
    if (!selectedItem || !date) {
      setError('Please select an item and enter a date.');
      return;
    }

    const selectedWishlistItem = wishlist.find(item => item.name === selectedItem);
    const targetDate = new Date(date);
    const currentDate = new Date();
    const timeDiff = (targetDate.getTime() - currentDate.getTime()<1)? 1 :(targetDate.getTime() - currentDate.getTime()) ;
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

    if (targetDate.getTime() - currentDate.getTime()<1){
      setError('Selected a date in the future.');
      return;
    }
    
    const savingsPerDay = (selectedWishlistItem.price / daysDiff).toFixed(2);
    setSavingCalc([{spd: savingsPerDay, ipd: ((income/30).toFixed(2)), dd: daysDiff}]);
    setIsModalOpen(true);
  };

  const handleGoalUpdate = () =>{
     const selectedWishlistItem = wishlist.find(item => item.name === selectedItem);
     const newGoal = { name: selectedItem, price: selectedWishlistItem.price, needby: new Date(date).toISOString() };

     setGoal(prevGoal => {
       const existingIndex = prevGoal.findIndex(item => item.name === selectedItem);
       if (existingIndex !== -1) {
         // Overwrite the existing item
         const updatedGoal = [...prevGoal];
         updatedGoal[existingIndex] = newGoal;
         localStorage.setItem('goal', JSON.stringify(updatedGoal));
         return updatedGoal;
       } else {
         // Add new item
         const updatedGoal = [...prevGoal, newGoal];
         localStorage.setItem('goal', JSON.stringify(updatedGoal));
         return updatedGoal;
       } 
     });
     //setGoal([...goal, {name:selectedItem, price: selectedWishlistItem.price, needby:(new Date(date))}]);
     //localStorage.setItem('goal', JSON.stringify(goal));
     setDoGoalUpdate(true); 
     closeModal();
   };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Styled.SavingsCalculatorContainer>
      <Styled.Card>
      <Styled.Title>Savings Calculator</Styled.Title>
      <p>Need something by a specific date? We are here to help!</p>
      <Styled.Dropdown value={selectedItem} onChange={(e) => setSelectedItem(e.target.value)}>
        {wishlist.map((item, index) => (
          <option key={index} value={item.name}>
            {item.name}
          </option>
        ))}
      </Styled.Dropdown>
      <Styled.Input
        type="date"
        value={date}
        min={getTodayDate()}
        onChange={(e) => setDate(e.target.value)}
        placeholder="Enter the date you wish to purchase the item"
      />
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <Styled.CalculateButton onClick={handleCalculate}>Calculate!</Styled.CalculateButton>
      
      {isModalOpen && (
        <Styled.Modal>
          <Styled.ModalContent>
            <Styled.Modalh2>Your Result!</Styled.Modalh2>
            {(parseFloat(savingCalc[0].spd)<parseFloat(savingCalc[0].ipd)) ? (
          <Styled.Instructions>
            <Styled.InstructionItem>You will need to save ${savingCalc[0].spd} per day from your daily income of ${savingCalc[0].ipd} for {savingCalc[0].dd} days to buy {selectedItem}.</Styled.InstructionItem>
            <Styled.ModalButton onClick={handleGoalUpdate}>Add this to your Savings Goal</Styled.ModalButton>
          </Styled.Instructions>
          ) : (
          <Styled.Instructions>
            <Styled.InstructionItem>You will need to save ${savingCalc[0].spd} per day for {savingCalc[0].dd} days to buy {selectedItem}.</Styled.InstructionItem>
            <Styled.InstructionItem>Your income is ${savingCalc[0].ipd} per day</Styled.InstructionItem>
            <Styled.InstructionItem>It is not possible to save this amount in the given timeframe.</Styled.InstructionItem>
          </Styled.Instructions>
          )}
          <Styled.ModalCloseButton onClick={closeModal}>Close</Styled.ModalCloseButton>
          </Styled.ModalContent>
        </Styled.Modal>
      )}
      {goal.length > 0 && (
        <>
          <Styled.Title>Saving Goals</Styled.Title>
          <Styled.Instructions>
          {goal.map((item, index) => (
          <Styled.InstructionItem key={index}>
          {item.name} : ${parseFloat(item.price).toFixed(2)} saved by {new Date(item.needby).toLocaleDateString()}
          </Styled.InstructionItem>
          ))}
          </Styled.Instructions>
        </>
       )}
      </Styled.Card>
      <Link to="/dashboard">Return to Dashboard</Link>
    </Styled.SavingsCalculatorContainer>
  );
}

export default SavingsCalculator;
