import React, { useState , useEffect} from 'react';
import styled from 'styled-components';
import {doc, setDoc} from 'firebase/firestore';
import {auth} from './firebase';
import {db} from './firebase';
import * as Styled from './StyledComponents';
import {Link, useNavigate} from 'react-router-dom';

function LogIncome() {
  const [tasks, setTasklist] = useState(JSON.parse(localStorage.getItem('tasks'))|| []); //from Dashboard
  const [total, setTotal] = useState(tasks.reduce((sum, task) => sum + parseInt(task.amount, 10), 0));
  const [newTask, setNewTask] = useState('');
  const [newTaskAmount, setNewTaskAmount] = useState(0);
  const [doUpdate, setDoUpdate] = useState(false);
  const user = auth.currentUser; 
  const navigate= useNavigate();

  useEffect(() => {
    const updateTaskList = async() => {
      try{
        console.log("Update database for wishlist from user:", user.uid);
        const wishDocRef =  doc(db,'wishlist', user.uid);
        await setDoc(wishDocRef, {tasks: tasks}, {merge:true});
        await setDoc(wishDocRef,{income:total}, {merge:true});
     
        setTimeout(() => navigate('/dashboard'), 1000);

      } catch (error) {
        console.error('Error updating tasks data',error);
      }
    };
    if(doUpdate){
      console.log("Total:", total);
      localStorage.setItem('income', JSON.stringify(total));
      updateTaskList();
    } //write to firestore for this user
  }, [doUpdate, user]);

  const addTask = async() => {
    console.log("Add task: ", newTask, " Amount:", newTaskAmount);
    if (newTask.trim()) {
      const updatedTasks = [...tasks, {name: newTask.trim(), amount: newTaskAmount.trim()}];
      const newTotal = updatedTasks.reduce((sum, task) => sum + parseInt(task.amount, 10), 0);
      setTasklist(updatedTasks);
      localStorage.setItem('tasks', JSON.stringify(tasks));
      console.log("Add Tasks: ", tasks);
      setTotal(newTotal);
      localStorage.setItem('income', JSON.stringify(total));
      console.log("Add task total: ", total);
      setNewTask('');
      setNewTaskAmount('');
      setDoUpdate(true); 
    }
  };

  const delTask = (index) => {
    const updatedTasks = tasks.filter((_, i) => i!==index);
    setTasklist(updatedTasks);
    localStorage.setItem('tasks', JSON.stringify(updatedTasks));
    const newTotal = updatedTasks.reduce((sum, task) => sum + parseInt(task.amount, 10), 0);
    setTotal(newTotal);
    setDoUpdate(true); 
  };

  //const handleDoneTaskUpdate= () => {
    //Update the localStorage
    //localStorage.setItem('tasks', JSON.stringify(tasks));
    //Update the total
    //setTotal(tasks.reduce((sum, task) => sum + parseInt(task.amount, 10), 0));
    //localStorage.setItem('income', JSON.stringify(total));
    //setDoUpdate(true); 
  //};

  return (
    <Styled.LogTasksContainer>
      <Styled.Title> Log your monthly income here!</Styled.Title>
        <p>Let me guess... You found some money on trees? No worries! Log extra
        money making here!
      </p>
      <Styled.List>
        {tasks.map((task, index) => (
          <Styled.ListItem key={index}>
            <span> {task.name}</span>
            <span>  ${task.amount}</span>
            <Styled.CrossButton onClick={()=>delTask(index)}>X</Styled.CrossButton>
          </Styled.ListItem>
        ))}
      </Styled.List>
      <Styled.Target>Total: ${total}</Styled.Target>
      <Styled.Input
            type="text"
            value={newTask}
            onChange={(e) => setNewTask(e.target.value)}
            placeholder="Name for New Income Source"
          />
          <Styled.Input
            type="number"
            value={newTaskAmount}
            onChange={(e) => setNewTaskAmount(e.target.value)}
            placeholder="Amount earned from New Income Source"
          />
          <Styled.AddButton onClick={addTask}>+ Add income to your income list</Styled.AddButton>
      
          <Link to="/dashboard">Return to Dashboard</Link>
    </Styled.LogTasksContainer>
  );
}

export default LogIncome;
