import React, { useState, useEffect } from 'react';
import * as Styled from './StyledComponents';
import { useNavigate , Link} from 'react-router-dom';
import {doc, setDoc} from 'firebase/firestore';
import {auth} from './firebase';
import {db} from './firebase';

function LogTarget() {
  const navigate = useNavigate();
  const [wishlist,setwishList] = useState(JSON.parse(localStorage.getItem('wishlist') || []));
  const income = JSON.parse(localStorage.getItem('income') || 0);
  const [target, setTarget] = useState(JSON.parse(localStorage.getItem('target') || 20));
  const [value, setValue] = useState(''); // Tracks input value
  const [error, setError] = useState('');
  const [doUpdate, setDoUpdate]= useState(false);
  const user = auth.currentUser; 

  useEffect(() => {
    const updateTarget = async() => {
      try{
        console.log("Update database for savings target from user:", user.uid);
        const DocRef =  doc(db,'wishlist', user.uid);
        if(target!==undefined){
          await setDoc(DocRef, {target: target}, {merge:true});
          localStorage.setItem('target', JSON.stringify(target));
          //setTimeout(() => navigate('/dashboard'), 1000);
        }
      } catch (error) {
        console.error('Error updating savings target',error);
      }
    };
    updateTarget(); //write to firestore for this user
  }, [doUpdate]);

  const handleTargetUpdate = ()=> {
    setTarget(parseFloat(target));
    //Update the localStorage
    localStorage.setItem('target', JSON.stringify(parseFloat(target)));
    setDoUpdate(true); 
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    // Regular expression to validate percentage (0-100 with up to 2 decimal places)
    const regex = /^(100(\.0{1,2})?|(\d{1,2}(\.\d{0,2})?))$/;
    if (inputValue === '' || regex.test(inputValue)) {
      setValue(inputValue); // Update input value
      setError(''); // Clear error if valid
      // If valid and not empty, assign it to "target"
      if (inputValue !== '') {
        setTarget(parseFloat(inputValue)); // Convert to a number and assign to "target"
      } else {
        setTarget(0); // Reset "target" if input is cleared
      }
    } else {
      setError('Please enter a valid percentage (0-100 with up to 2 decimal places).');
    }
  };

  return (
    <Styled.SavingsCalculatorContainer>
      <Styled.Card>
      <Styled.Title>Update monthly saving target </Styled.Title>
      <h5>We will set this to 20% of your income</h5>
      <Styled.Target>Saving Target: ${(target*income/100)} ({target}%)</Styled.Target>
      <Styled.Input
            type="text"
            value={value}
            onChange={handleChange}
            placeholder="Type % between 0-100 here..."
      />
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {target != null && (
        <p style={{color:'green'}}>Verified Perentage:{target}%</p>
      )}
      <Styled.CalculateButton onClick={handleTargetUpdate}>Update your monthly saving target</Styled.CalculateButton>
      <ul>
        <h5>Budgeting Rule of Thumb:</h5>
        <li>50% for essentials (e.g. lunch money)</li>
        <li>30% for fun (e.g. social activities)</li>
        <li>20% for future (e.g. Centsible spending)</li>
      </ul>
      </Styled.Card>
      <Link to="/dashboard">Return to Dashboard</Link>
    </Styled.SavingsCalculatorContainer>
  );
}

export default LogTarget;
